import React from 'react';
import { H3 } from '../../PageBuilder/Primitives/Heading';

import css from "./DealerContractContent.module.css";

const DealerContractContent = props => {
    return (
        <div className={css.container}>
            <H3 as="h2" className={css.title}>
                VERTRAG ÜBER DIE NUTZUNG VON FAHRZEUGINFORMATIONEN
            </H3>
            <p>Dieser Vertrag ("Vertrag") wird abgeschlossen zwischen:</p>
            <ol className={css.mainList}>
                <li>
                    <strong>DER DIENSTLEISTER</strong>
                    <p>dealgo.ch by Eliekhudari, im Folgenden als "Dienstleister" bezeichnet,</p>
                    <p>Adresse:</p>
                </li>
                <li>
                    <strong>DER HÄNDLER</strong>
                    <p>Jeder Autohändler, der sich auf der Website registriert, im Folgenden als "Händler" bezeichnet.</p>
                    <p>Gemeinsam als "Vertragsparteien" bezeichnet.</p>

                    <ol className={css.mainList2}>
                        <li>
                            <strong>
                                GEGENSTAND DES VERTRAGES
                            </strong>
                            <ol className={css.nestedList1}>
                                <li><p>Der Dienstleister betreibt eine Website, auf der Autoverkäufer ihre Fahrzeuge zum Verkauf anbieten können.</p></li>
                                <li><p>Der Händler möchte die Möglichkeit erhalten, Informationen zu den auf der Website des Dienstleisters aufgeführten Fahrzeugen zu erhalten und diese in Reservierung zu halten, um sie zu einem späteren Zeitpunkt zu kaufen.</p></li>
                            </ol>
                        </li>
                        <li>
                            <strong>
                                ZAHLUNG UND GEBÜHREN
                            </strong>
                            <ol className={css.nestedList2}>
                                <li><p>Der Händler erklärt sich damit einverstanden, dem Dienstleister eine nicht erstattungsfähige Gebühr von 300 CHF (ohne Steuern) plus zusätzlich 7,5% für die Mehrwertsteuer für jede Reservierung eines Fahrzeugs zu zahlen.</p></li>
                                <li><p>Der Händler muss bei der Registrierung eine Kaution von mindestens 10.000 CHF hinterlegen, die vom Dienstleister verwaltet wird. Diese Kaution wird verwendet, um die Website-Gebühren zu decken, sobald der Händler ein Fahrzeug reserviert oder in Reserve nimmt.</p></li>
                                <li><p>Der Händler stimmt zu, dass die Kaution oder der verbleibende Betrag davon für einen Zeitraum von 12 Monaten nicht abgehoben werden kann.</p></li>
                                <li><p>Der Händler verpflichtet sich, die Kaution erneut aufzufüllen, sobald der verbleibende Betrag weniger als 5000 CHF beträgt.</p></li>
                            </ol>
                        </li>
                        <li>
                            <strong>
                                NUTZUNGSRECHTE
                            </strong>
                            <ol className={css.nestedList3}>
                                <li><p>Dem Händler wird das Recht eingeräumt, die Informationen zu den reservierten Fahrzeugen ausschließlich für den persönlichen Gebrauch und die mögliche Kaufentscheidung zu verwenden.</p></li>
                                <li><p>Der Händler darf die Informationen nicht an Dritte weitergeben oder für andere Zwecke als den potenziellen Kauf der reservierten Fahrzeuge verwenden.</p></li>
                                <li><p>Der Händler verpflichtet sich, die Login-Informationen und Passwörter sowie die Informationen über Fahrzeughalter privat und sicher aufzubewahren und nicht mit Dritten zu teilen.</p></li>
                            </ol>
                        </li>
                        <li>
                            <strong>
                                HAFTUNGSAUSSCHLUSS
                            </strong>
                            <ol className={css.nestedList4}>
                                <li><p>Der Dienstleister übernimmt keine Haftung für die Richtigkeit oder Vollständigkeit der auf der Website angezeigten Fahrzeuginformationen.</p></li>
                                <li><p>Der Händler versteht und akzeptiert, dass der Dienstleister nicht für Transaktionen zwischen dem Händler und dem Autoverkäufer haftet.</p></li>
                            </ol>
                        </li>
                        <li>
                            <strong>
                                DATENSCHUTZ
                            </strong>
                            <ol className={css.nestedList5}>
                                <li><p>Der Dienstleister verpflichtet sich, die Datenschutzbestimmungen gemäß den geltenden Gesetzen und Vorschriften einzuhalten.</p></li>
                                <li><p>Der Händler erklärt sich damit einverstanden, dass seine personenbezogenen Daten für die Zwecke dieses Vertrags verwendet werden dürfen.</p></li>
                            </ol>
                        </li>
                        <li>
                            <strong>
                                STRAFE UND BEENDIGUNG
                            </strong>
                            <ol className={css.nestedList6}>
                                <li><p>Im Falle eines Missbrauchs der Dienste durch den Händler behält sich der Dienstleister das Recht vor, die Registrierung des Händlers zu stornieren, den Händler von der Nutzung der Website auszuschließen und die Kaution zu behalten, ohne sie zurückzuerstatten.</p></li>
                                <li><p>Der Händler stimmt zu, im Falle eines Verstoßes gegen diesen Vertrag eine Strafgebühr von 500.000 CHF an den Dienstleister zu zahlen, sowie sämtliche Gerichts- und Anwaltsgebühren, die im Zusammenhang mit dem Verstoß entstehen.</p></li>
                            </ol>
                        </li>
                        <li>
                            <strong>GERICHTSSTAND UND ANWENDBARES RECHT</strong>
                            <p>Dieser Vertrag unterliegt dem schweizerischen Recht. Alle Streitigkeiten aus diesem Vertrag werden vor dem zuständigen Gericht am Sitz des Dienstleisters ausgetragen.</p>
                        </li>
                        <li>
                            <strong>AKZEPTANZ</strong>
                            <p>Durch die Registrierung als Händler auf der Website, Bereitstellung der erforderlichen Kaution und Nutzung der Website erklärt sich der Händler mit den in diesem Vertrag festgelegten Bedingungen, einschließlich der Strafklausel, einverstanden.</p>
                        </li>
                    </ol>
                </li>
            </ol>
        </div>
    );
};

export default DealerContractContent;